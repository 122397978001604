/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-18 11:33:53
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-14 11:38:57
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/SynthReport/SynthTable/dimesion.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const allDimesion = [
  {
    key: 'publisher_id',
    name: '供应商',
    checked: false,
    prop: 'publisherName',
    width: 120
  },
  {
    key: 'place_id',
    name: '广告位',
    checked: false,
    prop: 'placeName',
    width: 130
  },
  {
    key: 'dsp_place_id',
    name: '广告源',
    checked: false,
    prop: 'dspPlaceName',
    width: 120
  },
  {
    key: 'position_id',
    name: '广告样式',
    checked: false,
    prop: 'positionName',
    width: 100
  },
  {
    key: 'plat_id',
    name: '广告平台',
    checked: false,
    prop: 'platName',
    width: 100
  },
  {
    key: 'plat_account_id',
    name: '广告平台账户',
    checked: false,
    prop: 'platAccountName',
    width: 160
  },
  {
    key: 'os',
    name: '操作系统',
    checked: false,
    prop: 'os',
    width: 80
  },
  {
    key: 'country_code',
    name: '地区',
    checked: false,
    prop: 'countryName',
    width: 150
  },
  {
    key: 'bundle',
    name: '应用包名',
    checked: false,
    prop: 'bundle',
    width: 180
  },
  {
    key: 'traffic_place_id',
    name: '流量广告位ID',
    checked: false,
    prop: 'trafficPlaceId',
    width: 180
  }
]
